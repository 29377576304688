import React from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Faq10 = () => {
  return (
    <>
      <Layout>
        <Container>
          <Row>
            <div className="back-button mb-4 mt-3">
              <Link to="/faq/">&larr; Back</Link>
            </div>
            <Col sm="12">
              <div className="mb-5">
                <StaticImage
                  src="../../images/blue-background-woman-1200x600.jpg"
                  alt="Woman"
                  placeholder="none"
                  layout="fullWidth"
                />
                <h2 className="mt-3">
                  Can Lumineers<sup>&reg;</sup> be removed?
                </h2>
                <p>
                  Lumineers<sup>®</sup> can be removed by a licensed dentist to
                  reveal the original dentition at any time. Lumineers are
                  placed by dentists trained in painless minimally invasive
                  techniques that usually require no shots or aggressive
                  drilling. The minimally invasive procedure typically preserves
                  most of the original healthy tooth structure.
                </p>
              </div>
              <div className="mb-5 text-center">
                <Link to="../../about/" className="intro-button mb-5">
                  Learn More
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Faq10
